import { http } from '@/http/axios.js'

// 字典列表
export function dictListApi(params) {
  return http({
    url: `/company/basic/dict/list`,
    method: 'get',
    params
  })
}

// 字典详情
export function getDictDetail(id) {
  return http({
    url: `/company/basic/dict/detail`,
    method: 'get',
    params: { id }
  })
}
// 编辑字典
export function editDict(data) {
  return http({
    url: `/company/basic/dict/edit`,
    method: 'POST',
    data
  })
}
// 添加字典
export function addDict(data) {
  return http({
    url: `/company/basic/dict/add`,
    method: 'post',
    data
  })
}
// 删除字典
export function delDict(id) {
  return http({
    url: `/company/basic/dict/del`,
    method: 'post',
    data: { id }
  })
}

// 字典值列表
export function getDictValues(dict_key) {
  return http({
    url: `/company/basic/dictValue/list`,
    method: 'get',
    params: { dict_key }
  })
}

// 添加字典值
export function addDictValue(data) {
  return http({
    url: `/company/basic/dictValue/add`,
    method: 'post',
    data
  })
}
// 编辑字典值
export function editDictValue(data) {
  return http({
    url: `/company/basic/dictValue/edit`,
    method: 'POST',
    data
  })
}
// 删除字典值
export function delDictValue(id) {
  return http({
    url: `/company/basic/dictValue/del`,
    method: 'POST',
    data: { id }
  })
}
// 字典值详情
export function getDictValue(id) {
  return http({
    url: `/company/basic/dictValue/detail`,
    method: 'GET',
    params: { id }
  })
}
